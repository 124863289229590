export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () =>
  isBrowser() && window.sessionStorage.getItem('fastechAdmin')
    ? JSON.parse(window.sessionStorage.getItem('fastechAdmin'))
    : {}

const setUser = user => window.sessionStorage.setItem('fastechAdmin', JSON.stringify(user))

export const handleLogin = ({ password }) => {
  if (password === 'FSL2023@Financials') {
    return setUser({
      username: `fastech-admin`,
      name: `Fastech Admin`,
    })
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = callback => {
  setUser({})
  callback()
}
